<template>
  <div>
      <transition name="fade">
        <img class="icon" v-bind:src="src" v-on:load="onLoaded" v-show="loaded">&nbsp;
      </transition>
    
      
      <img v-if="!loading_photo" v-show="!loaded" class='loading_img' src="/loading.gif" alt="Loading..." />
      <img v-else v-show="!loaded" class='icon' :src="loading_photo" alt="Loading..." />


   </div>

</template>

<script>
export default{
  name: "LazyPhoto",
  props: ["src", "loading_photo"],
  data(){
    return{
      loaded: false
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    }
  },

}
</script>
<style scoped>
</style>
