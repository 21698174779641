<template>
    <div class='landing_page'>
        <div class='main_picture'>
          <!--<img src='/moving.gif' alt='Chet Enright Logo' />-->
          <LazyPhoto src="/moving.gif" loading_photo="/still.png" />
          <h1>CHESTER ENRIGHT</h1>
        
        </div>

        <div class="landing_nav_div sections" style="display: block;" >
            <div v-for="data in json.sections" v-bind:key="data.scrollTo">
            <a :href="data.scrollTo" v-if="data.external">

                <img class="grey" v-bind:src="data.src" height="40px" v-bind:alt="data.title" />
                <br>{{data.title}}
            </a>
            <a href="#" v-scroll-to="'' + data.scrollTo" v-else>

                <img class="grey" v-bind:src="data.src" height="40px" v-bind:alt="data.title" />
                <br>{{data.title}}
            </a>

            </div> 
            
        </div>
    </div>
</template>

<script>
    import json from '../../data/nav.json'
    import LazyPhoto from "@/components/LazyPhotos"
    export default {
        Name: "Header",
        components: {
          LazyPhoto
        },
        props: [],
        methods: {

        },
        data() {
            return {
                json: json
            }

        }
    }
</script>

<style>
    /* Start page */
    h1{
      font-weight: normal;
      border-bottom: 1px solid #cfcfcb;
      padding-bottom:5px;
      width: 50%;
      min-width: 300px;
      margin: auto;
    }
    div.landing_page {
        text-align: center;
        margin: auto;
        height: 90%;
        background-color: #eee;
    }

    @media screen and (max-width: 1280px) and (min-width: 1160px) {
        div.landing_page {
            padding-top: 5%;
        }
    }

    @media screen and (max-width: 1500px) and (min-width: 1280px) {
        div.landing_page {
            padding-top: 5%;
        }
    }

    @media screen and (min-width: 1500px) {
        div.landing_page {
            padding-top: 5%;
        }

    }


    div.landing_page div.main_picture {
        width: 100%;
        clear: both;
        margin: auto;
        margin-bottom: 40px;

    }

    div.landing_page div.main_picture img {
        width: 25%;
        max-width: 400px;
        margin-top:-20px;
    }

    @media screen and (max-width: 760px) {

        div.landing_page div.main_picture img {
            width: 50%;
        }
    }

    @media screen and (min-width: 1500px) {
        div.landing_page div.main_picture img {
            width: 20%;
            margin-bottom: 40px;
        }
    }

    div.landing_page div.landing_nav_div {
        width: 90%;
        margin: auto;
        height: 100%;
        min-height: 100px;
    }

    div.landing_nav_div div {
        float: left;
        width: 16%;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    @media screen and (max-width: 760px) {
        div.landing_nav_div div {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    div.landing_nav_div div img {
        cursor: pointer;
        -webkit-filter: grayscale(1);
        -webkit-filter: grayscale(100%);
        filter: gray;
        filter: grayscale(100%);

    }

    div.landing_nav_div div:hover img{
        -webkit-filter: none;
        -webkit-filter: none;
        filter: none;
     
            }

    div.landing_nav_div a {
        font-size: 18pt;
        font-family: 'Open Sans', Arial, Helvetica;
        text-decoration: none;
        color: #242426;
        color: rgb(62, 72, 89);
    }

    div.landing_nav_div a:hover {
        text-decoration: underline;
        color: #242426;
        color: rgb(62, 72, 89);

    }

    div.landing_nav_div a:visited {
        text-decoration: none;
        color: #242426;
        color: rgb(62, 72, 89);
    }
</style>
