<template>
  <div class='contact sections' name="contact" id="contact"> 
	<div class='contact_left'>
		<div class='photo'>
			<img height=250px src='photos/me.png' alt='Profile Picture of Chester Enright' /><br class='clear' />
		</div>
		<div class='text'>
			<b>Chester Enright<br/>
			<a href='tel:+12176438224'>(217) 643-8224</a><br /></b><br />
			<a href='mailto:chet.enright@gmail.com'>chet.enright@gmail.com</a><br />
			<br />
			<br />
			Like what you see here?<br />Feel free to give me a call or email<br /> and we can talk!
		</div>
	</div>
	<div class='contact_right'>
		<h2>Enough about Me!  Let's Talk about You!</h2>
		<form id='contact_form'
      ref="form"
      :action="url"
      method="POST"
      >
      <!--<input type='hidden' id='random' value='<?=$captchas->random()?>' />-->
			<div class='contact_form'>
				<div class='top_row'>
          <input name ="name" type='text' id='Name' :placeholder="name"/>
					<input name="_replyto" type='text' id='Email' :placeholder="email">
				</div>
        <textarea name="message" id='Message' cols=45 rows=7 :placeholder="msg"></textarea>
				<div class='submit_button' @click="sendEmail()" >SEND TO CHET</div>
			</div>
		</form>

<!-- modify this form HTML and place wherever you want your form -->
<br /><br />
	</div>


<br class='clear' />

<div class='legal'>
  © {{year}}  - Chester Enright.  All rights reserved.<br />
All trademarks are owned by
their respective parties.  Trademarked items are used for the sole purpose of identification
 and do not indicate a relationship between the trademark holder and chetenright.com.  All Altamont
 Company trademarks are owned by Altamont Company and are used with permission.  <br /><br />
 Icons are generously provided by <a href='http://icons8.com'>Icons8</a>.  Other icons have been sufficiently modified to be considered original work.
 <br />
</div>
  </div>

  </template>
<script>
import axios from 'axios'
export default{
  name: "Contact",
  data(){
    return{
      received: "",
      url: "https://formspree.io/xnqnvedj",
      name: "Your Name?",
      email: "Your Email?",
      msg: "Type your message here.",
      year: (new Date()).getFullYear()
    }
  },
  methods:{
    sendEmail: function(){
      var form = this.$refs.form    
      axios.post(this.url, new FormData(form)).then(res=>{
        if(res.status == 200){
          this.$bvToast.toast('Email Sent!  Thanks!',{ 
          variant: "success",
          solid: true
        })
        }
      }).catch(e=>{ 
        this.$bvToast.toast(e,{ 
          variant: "success",
          solid: true
        })

      })
  },
  }
}
</script>
<style>
/* Legal area */
.legal{
padding-top: 20px;
    border-top: 1px solid #242426;
    margin: auto;
    margin-top: 20px;
    text-align: left;
    font-size: 10pt;
  }
/* Contact Page */

div.contact {
        height: 100%;
        width: 90%;
        margin: auto;
        min-height: 800px;
        max-width:1500px;
}

div.contact div.contact_left {
        width: 25%;
        float: left;
        text-align: center;
        margin-top: 20px;
        padding-top: 20px;
        border-right: 1px solid #5b2416;
}

@media screen and (max-width: 1000px) and (min-width: 760px) {
        div.contact div.contact_left {
                width: 100%;
                padding: 2%;
                text-align: center;
                padding-top: 20px;
                padding-right: 40px;
                margin: auto;
                height: 350px;
                border-bottom: 1px solid #5b2416;
                border-right: none;

                margin-top: 20px;
        }
}
@media screen and (max-width: 760px) {
        div.contact div.contact_left {
                width: 100%;
                padding: 2%;
                text-align: center;
                padding-top: 20px;
                padding-right: none;
                margin: auto;
                height: 500px;
                border-bottom: none;
                border-right: none;

                margin-top: 20px;
        }
}

@media screen and (max-width: 1000px) and (min-width: 760px) {
        div.contact div.contact_left div.text {
                float: right;
                width: 50%;
                text-align: left;
        }
}

@media screen and (max-width: 760px) {

        div.contact div.contact_left div.text {
                float: none;
                width: 100%;
                text-align: center;
        }
}

div.contact div.contact_left h2 {
        text-align: center;
}

div.contact div.photo img {
        border: 2px solid #84848b;
}

@media screen and (max-width: 1000px) and (min-width: 760px) {
        div.contact div.photo img {
                float: left;
        }
}
@media screen and (max-width: 760px) {
        div.contact div.photo img {
                float: none;
        }
}


div.contact div.contact_right {
        width: 60%;
        float: left;
        padding: 2%;
        padding-top: 20px;
        padding-left: 40px;
}

@media screen and (max-width: 1000px) and (min-width: 760px) {
        div.contact div.contact_right {
                width: 100%;
                float: none;
                clear: both;
                padding: 0;
                padding-top: 20px;
        }
}

@media screen and (max-width: 760px) {
        div.contact div.contact_right {
                width: 100%;
                float: none;
                clear: both;
                padding: 0;
                padding-top: 20px;
                text-align: center;
        }
}

div.contact div.contact_right h2 {
        text-align: left;
        margin-top: 20px;
        font-size: 17pt;
}

div.submit_button {
      width: 50%;
    margin: auto;
    font-size: 16pt;
    height: 40px;
    text-align: center;
    padding-top: 4px;
    background-color: #5b2416;
    color: white;
    cursor: pointer;
    float: right;
    /* padding-right: 5%;
}

div.submit_button:hover {
        font-size: 16pt;
        -moz-transition: all 200ms linear;
        -webkit-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear;
}

@media screen and (max-width: 1160px) and (min-width: 760px) {
        div.submit_button {
                width: 40%;
        }
}

@media screen and (max-width: 760px) {
        div.submit_button {
                width: 100%;
        }
}

div.contact input,
div.contact textarea {
        padding: 5px;
        -webkit-box-sizing: border-box;
        /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;
        /* Firefox, other Gecko */
        box-sizing: border-box;
        /* Opera/IE 8+ */
}
div.top_row input#Name {
        width: 49%;
        height: 25px;
        position: relative;
        padding: 5px;
        left: -7px;
}

@media screen and (max-width: 760px) {
        div.top_row input#Name {
                width: 100%;
                float: left;
                left: 0;
                margin-bottom: 10px;
        }
}

div.top_row input#Email {
        width: 49%;
        padding: 5px;
        float: right;
        height: 25px;
}

@media screen and (max-width: 760px) {
        div.top_row input#Email {
                width: 100%;
                float: left;
                margin-bottom: 10px;
        }
}

div.contact_form div.top_row {
        margin-bottom: 10px;
}

div.contact_form textarea#Message {
  padding: 5px;
        margin-bottom: 5px;
        width: 100%;
}

div.contact_form div.captcha_entry {
        float: left;
        width: 50%;
        text-align: left;
}
@media screen and (max-width: 760px) {
        div.contact_form div.captcha_entry {
                width: 100%;
        }
}

div.contact_form div.captcha_entry input#password {
        width: 240px;
        margin-bottom: 10px;
}

</style>

