<template>
  <div>
    <span @click="openModal"><LazyPhoto class='hover' :src="src" /></span>

  <b-modal size="lg" :id="title" :title="title" ok-only>
    <div class='image'>
<LazyPhoto class='hover' :src="src" />
    </div>
  </b-modal>
  </div>
</template>

<script>
import css from 'bootstrap/dist/css/bootstrap.css'
import second_css from 'bootstrap-vue/dist/bootstrap-vue.css'

import LazyPhoto from '@/components/LazyPhotos'

export default{
  name: "PhotoModalHelper",
  components: {
    LazyPhoto
  },
  data(){
  return{
  }
  },
  mounted: function(){
      
  },
  methods:{
    openModal: function(){
      this.$bvModal.show(this.title) 
    }
  },
  props: ["src", "title"]
}
</script>
<style lang="scss" scoped>
div.image{
  text-align: center;
}
/deep/ .modal-body{
  background-color: #eee;
}
/deep/ img{
  max-width: 100%;
}
.hover{
  cursor: pointer;
  border: none !important;
}
</style>
