<template>
	<div class='shell grid_div' id='gridview'>
		<div class='inset' v-for="project in json.projects">
			<div class='pic'>
        <PhotoModalHelper :src="project.photo" :title="project['data-title']" />
			</div>
			<div class='text'>
				<div class='title'>{{project.title}}</div>
				{{project.text}} <br />

				<span v-if="project.start == project.end">
					{{project.start}} | 
				</span>
				<span v-else>
					{{project.start}} - {{project.end}} | 
				</span>

				<span v-for="(tech,idx) in project.technologies">
					{{tech}} 
					<span v-if="idx < project.technologies.length-1">
						/
					</span>
				</span>
			</div>
		</div>

	</div>
</template>

<script>
import json from '../../data/portfolio.json'
import LazyPhoto from '@/components/LazyPhotos'
import PhotoModalHelper from '@/components/PhotoModalHelper'
export default{
  name: "Grid",
	data(){
	return{
	json:json
}
},
	components: {
		LazyPhoto,
    PhotoModalHelper
	}
}
</script>

<style scoped>
</style>
