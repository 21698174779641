import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// Global Scroll To
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

// Toggle Button
import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

//Lightbox
import Lightbox from 'vue-easy-lightbox'
Vue.use(Lightbox)

// Bootstrap
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)


// Visibility Observer
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

// Bootstrap Toasts
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)


//App loading
router.beforeEach((to, from, next) => {

  app.loading = true
	next()
})

router.afterEach((to, from, next) => {
	app.loading = false
})

new Vue({
	el : "#app",
  router,
  render: h => h(App)
}).$mount('#app')
