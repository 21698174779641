<template>
  <div class='cv sections'>
    <div id="body_textarea_span" name="resume">
      <div class='no-bg'>
        <div class='link' @click="()=>{
        if (page == 1){ page = 2}else{ page = 1}}"
                                 >Next Page</div>
        <div class='right'>
          {{page}}/2
        </div>
      </div>
      <br />
      <pdf src="./Resume.pdf" :page="page">
      <template slot="loading">
   <img src='/loading_white.gif' height=100px style='margin-top:40px; margin-bottom:40px;' />
    </template>

      </pdf>
    </div>
    </div>
  </div>


</template>
<script>
import pdf from 'pdfvuer'
export default{
  name: "Resume",
  components: {
    pdf
  },
  data(){
    return{
      frame: "",
      page: 1
    }
  },
}

</script>
<style scoped>

/* Resume section */
div.no-bg{
  background: none !important;
}
/deep/ iframe{
  width: 100%;
  height: 99%;
}
div.link{
  float: left;
  background: none !important;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  width: 49%;
  padding-left: 1%;
  text-align: left !important;
}
div.right{
  float: left;
  width: 49%;
  text-align: right !important;
  background: none !important;
}
div.cv {
        margin-top: 5%;
        width: 100%;
        clear: both;
        background-image: url('/photos/bg1.jpg');
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 60px;

}

div#body_textarea_span div {
  padding-top:5px;
        text-align: center;
        margin: auto;
        max-width: 800px;
        -webkit-overflow-scrolling: touch;
}


</style>
