<template>
  <div class='tooling sections'>
    <div class='tooling_content'>
      <h2>TOOLING</h2>
      <div class='description'>
        Any professional will tell you the importance of keeping their tools
        well maintained.  In technology, many of those tools are made of silicon or steel, but
        the most important tool is often less apparent - the mind.  Listed below are some
        of the tools I use both virtually and physically, as well as different areas I am working towards learning.

      </div>

      <h3>TECHNOLOGIES</h3>
      <div class='shell animated-scroll fade'>

        <div v-for="tech in json.technologies" class='tech_div'>
          <div class='tech_image'>
            <img :height="tech.img_height" :src="tech.icon" alt="Tech icon" />
          </div>
          <ul>
            <li v-for="item in tech.text" v-html="item">
            </li>
          </ul>
        </div>

      </div>
    </div>
    </div>
</template>

<script>
import json from '../../data/stack.json'
export default{

  name: "Tooling",
  data(){
    return{
      json: json
    }
  },

}
</script>
<style lang="scss">
/* Global blue */
$blue: rgba(62,72,89,1);
/* Tooling page */
div.tooling_content {
        padding: 20px;
}

div.tooling_content div.description {
        width: 90%;
        margin: auto;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 40px;
}

div.tooling_content h3 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
}

div.tech_div {
        margin-top: 20px;
        margin-bottom: 20px;
        float: left;
        width: 28%;
        margin-left: 1%;
        margin-right: 1%;
        text-align: center;
        /* background-color: grey; */
        padding: 10px;
        padding-top: 20px;
        /* box-shadow: 5px 5px black; */
        padding-left: 20px;
        border: 1px solid $blue;
        min-width: 250px;
}

div.tech_div ul {
        margin: auto;
        list-style-type: none;
        text-align: left;
}
div.tech_div ul li { 
        margin-top: 3px; 
        margin-bottom: 3px; 
} 
 
div.tech_div div.tech_image { 
        height: 100px; 
} 
 
.charts { 
        height: 150px !important; 
        width: 150px !important; 
        margin: auto; 
        margin-top: 20px; 
        margin-bottom: 20px; 
 
} 
 
.checked { 
        color: #2ABF70; 
} 
 
.pct_detail { 
        position: relative; 
        top: -103px; 
} 
 

</style>
