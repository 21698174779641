<template>
  <div class='projects sections'>
    <div class='portfolio_content'>
      <h2>SELECTED PROJECTS</h2>
      <div class='selector_div'>

        <img height=40px src='photos/grid.svg' alt='Grid icon' onclick='javascript:showGrid();' />

        <toggle-button :height=30 :width=50 color="rgb(62, 72, 89)" class='toggle_button' v-model="selected" @change="handleChange"/>

        <img height=40px onclick='javascript:showTimeline();' src='photos/calendar.png' alt='Calendar icon' />
      </div>
      <Grid v-if="!selected"/>
      <Timeline @scroll="emitScroll()" v-if="selected"/>


    </div>
  </div>
</template>

<script>
import Timeline from '@/components/Timeline'
import Grid from '@/components/Grid'



export default{
  name: "Projects",
  methods: {
    emitScroll: function(){
      this.$emit("scroll")
    },
   handleChange: function(){
      if(this.selected){
        this.$scrollTo("div.projects", 100, {offset: -10})
      }else{
        this.$scrollTo("div.projects", 100, {offset: -11})
      }
    }
 
  },
  components: {
    Timeline,
    Grid
  },
  data(){
    return{
      selected: true
    }
  }
}
</script>

<style lang="scss">
/* Global Blue */
$blue: rgba(62,72,89,1);
/* Toggle button */
.toggle_button
{
  margin-bottom:0;
  margin-left: 10px;
  margin-right: 15px;
}


/* Timeline section */

div.inset_year {
    width: 100%;
}

div.inset_year div.year {
    float: left;
    font-size: 20pt;
    padding-right: 5%;
    width: 15%;
    border-bottom: 2px solid $blue;
    text-align: left;
    color: $blue;
}

@media screen and (max-width: 700px) {
    div.inset_year div.year {
        float: none;
        width: auto !important;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 30pt;
        padding-right: 0;
    }
}

.grey_tag {
    background-color: #b6b6b6 !important;
}

div.inset_year div.detail_wrapper {
    border-left: 2px solid $blue;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    min-height: 40px;
    margin-bottom: 0;
    width: 85%;
    float: left;
}

@media screen and (max-width: 700px) {
    div.inset_year div.detail_wrapper {
        width: 100%;
        border-left: none;
        padding-top: 20px;
    }
}

div.inset_year div.year_detail {
    background-color: lightgrey;
    margin-bottom: 20px;
    margin-left: 20px;
    padding: 2%;
    overflow: hidden;
    width: 40%;
    float: left;
    box-shadow: 3px 3px $blue;
}

@media screen and (max-width: 640px) {
    div.inset_year div.year_detail {
        width: 90%;
        margin: auto;
        float: none;
        margin-bottom: 40px;
    }
}

div.inset_year div.year_detail div.tags {
    overflow: hidden;
    width: 100%;
    position: relative;
    bottom: -20px;
}

div.inset_year div.year_detail div.tags div {
    float: left;
    /* min-width: 25%; */
    /* height: 40px; */
    background-color: $blue;
    cursor: pointer;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 2%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #efefeb;
}

div.inset_year div.year_detail div.text {
    float: left;
    width: 45%;
    padding: 5px;
}

@media screen and (max-width:1300px) {
    div.inset_year div.year_detail div.text {
        width: 100%;
        margin-top: 10px;
    }
}

div.inset_year div.year_detail div.photo {
    float: left;
    padding-top: 5px;
    max-width: 45%;
    margin-right: 1%;
    overflow: hidden;
}

@media screen and (max-width:1300px) {
    div.inset_year div.year_detail div.photo {
        float: none;
        max-width: 100%;
        margin-right: 0;
        overflow: hidden;
    }
}

div.inset_year div.year_detail div.photo img {
    max-width: 90%;
    border: 7px solid #efefeb;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

div.inset_year div.year_detail div.photo img:hover,
div.inset div.pic img:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

h3.title {
    color: rgb(62, 72, 89);
    font-size: 15pt;
    font-weight: bold;
    text-transform: uppercase;
}

div.selector_div {
    position: relative;
    width: 50%;
    left: 50%;
    bottom: 35px;
}

@media screen and (max-width:1300px) {
    div.selector_div {
        clear: both;
        min-width: 320px;
        position: inherit;
        margin: auto;
        margin-bottom: 20px;
    }
}

div.selector_div img {
    cursor: pointer;
}

hr {
    margin-top: 50px;
}


/* Portfolio section */

.lazyload {
    display: none;
}

.loading_img {
  max-height: 100px;
}


div.projects {
    margin: auto;
    margin-top: 5%;
    clear: both;
    width: 95%;
}

div.projects div.portfolio_content {
    text-align: center;
    margin: auto;
}

div.shell {
    width: 99%;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
    clear: both;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 1160px) and (min-width: 760px) {
    div.shell {
        /*margin-bottom: 40px;*/
        text-align: center;
        clear: both;
        width: 100%;
        margin: auto;
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1160px) {
    div.shell {
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 1500px) and (min-width: 1280px) {
    div.shell {
        width: 90%;
        margin-bottom: 40px;
        text-align: center;
        clear: both;
        margin: auto;
    }
}

@media screen and (max-width: 760px) {
    div.shell {
        margin-bottom: 200px;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
    }
}

div.shell div.inset {
    float: left;
    width: 17%;
    min-width: 250px;
    margin-left: 1%;
    margin-right: 1%;
    /*background-color: #efefed;*/
    background-color: rgb(62, 72, 89);
    padding: 1%;
    padding-bottom: 2%;
    margin-bottom: 40px;
    color: #efefed;
    font-size: 11pt;
    font-family: 'Open Sans', Arial, Sans-Serif;
    box-shadow: 5px 5px #b6b6be;
}

@media screen and (max-width: 1160px) and (min-width: 760px) {
    div.shell div.inset {
        width: 45%;
        margin-right: 0;
        height: 350px;
    }
}

@media screen and (max-width: 1280px) and (min-width: 1160px) {
    div.shell div.inset {
        height: 350px;
    }
}

@media screen and (max-width: 1500px) and (min-width: 1280px) {
    div.shell div.inset {
        margin-left: 2%;
        margin-right: 2%;
        width: 25%;
    }
}

@media screen and (max-width: 760px) {
    div.shell div.inset {
        width: 100%;
        height: 350px;
        margin-left: 0;
        padding-left: 0;
    }
}

div.shell div.inset div.pic {
    height: 180px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px;
}

div.inset div.pic img {
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

div.shell div.inset div.pic img {
    margin: auto;
    max-width: 100%;
}

div.shell div.inset div.title {
    font-weight: bold;
    margin-top: 5px;
    font-size: 14pt;
    margin-bottom: 15px;
    min-height: 40px;
    color: rgb(175, 204, 255);
    text-transform: uppercase;
}

div.shell p {
    clear: both;
    width: 100%;
    height: 40px;
    margin-top: 60px;
    padding-left: 10px;
}

</style>
