<template>
    <!-- Bio -->
    <div class="biography sections" >
        <div class="inset" >
            <div class="bio_information" >
                <h2 id='bio' name='bio'>ABOUT ME</h2>
                <img src="/photos/1.jpg" alt="Sayre and I photo" class="animated-scroll fade">
                <div class="text animated-scroll fade">
                    My name is
                    <b>Chester Enright.</b>&nbsp;&nbsp;I am a Christian, a programmer of things digital and physical, and I am deathly allergic to cats.  I graduated Taylor University Magna Cum Laude in Computer Science/Systems
                    in
                    2010. I currently work as the Technical Director at
                    <b>Altamont Company</b>, a manufacturing company for the
                    sporting goods industry. We are currently based in Champaign, IL.
                    <br>
                    <br>I have done a variety of web consulting work, including IDS Technologies in Ft. Wayne and
                    NextCrew in Chicago. I am
                    comfortable with a wide range of technologies, including PHP, MySQL, Visual C#.NET, Python,
                    HTML/CSS, JS/JQuery,
                    LAMP, etc.  Recently, most of my projects have been done in Python and Javascript (<b>Flask</b> and <b>Vue</b> to be precise).
                    <br>
                    <br>When not working, I enjoy spending time with my lovely wife (<b>Sayre</b>) and my two lovable/terrible dogs (<b>Nala</b>
                    and
                    <b>Finley</b>). My wife and I attend
                    <b>Mattis Ave Free Methodist Church</b>. Tennis is my sport of choice,
                    and when I'm not being a workaholic (either professionally or with the personal studies below),
                    I enjoy mechanical watches - Valjoux 7750s especially - and playing League of Legends (Plat 3).
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bio",
        props: {
        }
    };
</script>

<style scoped>
    /* Bio page */

    div.biography {
        width: 100%;
        clear: both;
        background-image: url('/photos/bg2.jpg');
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5%;
    }



    div.biography div.inset {
        overflow: hidden;
        background-color: #dfdfde;
        border: 2px solid #b6b6bd;
        width: 100%;
        margin: auto;
        padding-bottom: 7%;
    }



    div.biography div.inset div.bio_information {
        padding: 5%;
        text-align: center;
    }


    @media screen and (min-width: 1500px) {
        div.biography div.inset div.bio_information {
            padding: 2%;
            text-align: center;
        }
    }

    h2 {
        font-family: 'Montserrat', Arial, Sans-Serif;
        font-size: 26pt;
        color: #242426;
        margin-bottom: 20px;

    }

    div.biography div.inset div.bio_information img {
        border: 2px solid #b6b6b6;
        width: 30%;
        float: left;
        min-width: 250px;
    }

    @media screen and (max-width:1160px){

    div.biography div.inset div.bio_information div.text {
        text-align: left;
        margin-left: 0 !important;
      }

        div.biography div.inset div.bio_information img {

            margin-bottom: 20px;
          }
    }

    @media screen and (max-width: 1160px) and (min-width: 760px) {
    
        div.biography div.inset div.bio_information img {
            float: none;
            width: 50%;
        }
    }


    @media screen and (max-width: 760px) {
        div.biography div.inset div.bio_information img {
            float: none;
            width: 50%;
        }
    }

    div.biography div.inset div.bio_information div.text {
        text-align: left;
        font-size: 14pt;
        line-height: 30px;
        font-family: 'Open-Sans', Arial, Sans-Serif;
        float: left;
        width: 65%;
        margin-left: 3%;
    }

    @media screen and (max-width: 1160px) and (min-width: 760px) {
        div.biography div.inset div.bio_information div.text {
            width: 100%;
        }
    }

    @media screen and (max-width: 760px) {
        div.biography div.inset div.bio_information div.text {
            width: 100%;
        }
    }
</style>
