<template>
    <div>
        <Header />
        <Bio />
        <Projects @scroll="updateScroll()" />
        <Tooling />
        <Learning v-if="learn_visible" @mouse="updateLearn" />
        <Resume />
        <Contact />
    </div>
</template>

<script>
    // @ is an alias to /src

    import Bio from '@/views/Bio.vue'
    import Header from '@/components/Header.vue'
    import Projects from '@/views/Projects.vue'
    import Resume from '@/views/Resume.vue'
    import Tooling from '@/views/Tooling.vue'
    import Learning from '@/views/Learning.vue'

    import Contact from '@/views/Contact.vue'



    export default {
        name: 'home',
        components: {
            Bio,
            Header,
          Projects,
          Resume,
          Learning,
          Tooling,
          Contact
        },
      data(){
        return{
          learn_visible: true,
          update: true,
        }
      },
      methods: {
        updateLearn: function(it){
          if(it && this.update){
            this.learn_visible = false
            this.$nextTick(()=>{
              this.learn_visible = true
            })
            this.update = false
          }else{
            this.update = true
          }
        },
    updateScroll: function(){

        /* Internet Explorer code */

        var window_height = window.innerHeight
        var window_top_position = window.pageYOffset
        var window_bottom_position = window_top_position + window_height
      
        //console.log("window height: " + window_height + ",top: " + window_top_position + ", bottom: " + window_bottom_position)


        let animated_scroll = Array.from(document.querySelectorAll('.animated-scroll'))
        animated_scroll.forEach(item=>{
          var bound = item.getBoundingClientRect()
          var element_top_position = bound.top
          var element_bottom = bound.bottom
          var element_height = Math.abs(element_bottom - element_top_position)
          
          if(item.parentElement.className.indexOf("timeline_div") != -1 && item.innerHTML.indexOf("2019") != -1){

        //console.log("window height: " + window_height + ",top: " + window_top_position + ", bottom: " + window_bottom_position)

          //console.log("element top: " + element_top_position + ", element bottom: " + element_bottom + ", element height: " + element_height)
          }
          var offset = 200
          //if (element_bottom > (1 * element_height*2 + offset) && element_top_position > (1 * element_height*2 + offset)){

          if(element_bottom < 100 || element_top_position > window_height){

            item.classList.remove("in-view");
          }else{

            item.classList.add("in-view");

          }

        })
      }
    },
    destroyed: function(){
      window.removeEventListener('scroll', this.updateScroll);
    },
    mounted: function() {

        window.addEventListener('scroll', this.updateScroll);
        this.$el.querySelector(".landing_page").style.height = window.innerHeight + "px"
      
      this.$nextTick(()=>{

        var hash = window.location.hash.replace("#", "").replace("/", "")
        if(hash != ""){
          this.$scrollTo("#" + hash)
        }
      })
        //console.log(window.innerHeight)
        //console.log(this.$el.querySelector(".landing_page").style.height)

            },



}
</script>

<style lang="scss">

/* Global Styling */
$blue: rgba(62, 72, 89, 1);

/* Animation global */
.animated-scroll,.animate {
        opacity: 0;
        -moz-opacity: 0;
        filter: alpha(opacity=0);
        position: relative;
        -moz-transition: all 300ms linear;
        -webkit-transition: all 300ms linear;
        -o-transition: all 300ms linear;
        transition: all 300ms linear;

}

.animated-scroll.charts-animated.in-view {
        opacity: 1;
        -moz-opacity: 1;
        filter: alpha(opacity=1);
}

.animated-scroll.fade.in-view {
        opacity: 1;
        -moz-opacity: 1;
        filter: alpha(opacity=1);
        -moz-transition: all 500ms linear;
        -webkit-transition: all 500ms linear;
        -o-transition: all 500ms linear;
        transition: all 500ms linear;
}

.animated-scroll.slide-left {
        opacity: 0;
        -moz-opacity: 0;
        filter: alpha(opacity=0);
        -moz-transform: translate3d(-100px, 0px, 0px);
        -webkit-transform: translate3d(-100px, 0px, 0px);
        -o-transform: translate(-100px, 0px);
        -ms-transform: translate(-100px, 0px);
        transform: translate3d(-100px, 0px, 0px);
}

.animated-scroll.slide-left.in-view {
        opacity: 1;
        -moz-opacity: 1;
        filter: alpha(opacity=1);
        -moz-transform: translate3d(0px, 0px, 0px);
        -webkit-transform: translate3d(0px, 0px, 0px);
        -o-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate3d(0px, 0px, 0px);
}
.animated-scroll.slide-right {
        opacity: 0;
        -moz-opacity: 0;
        filter: alpha(opacity=0);
        -moz-transform: translate3d(100px, 0px, 0px);
        -webkit-transform: translate3d(100px, 0px, 0px);
        -o-transform: translate(100px, 0px);
        -ms-transform: translate(100px, 0px);
        transform: translate3d(100px, 0px, 0px);
}

.animated-scroll.slide-right.in-view {
        opacity: 1;
        -moz-opacity: 1;
        filter: alpha(opacity=1);
        -moz-transform: translate3d(0px, 0px, 0px);
        -webkit-transform: translate3d(0px, 0px, 0px);
        -o-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate3d(0px, 0px, 0px);
}

    /* Styles for chetenright.com */

    /* Globals */
    * {

        margin: 0;
        padding: 0;
        font-family: 'Open Sans', Arial, Helvetica, Sans-Serif;
    }

    br.clear {
        height: 1px;
        width: 100%;
        clear: both;
    }

    @media screen and (max-width: 1160px) and (min-width: 760px) {
        div.photo br.clear {
            display: none;
        }
    }

    @media screen and (max-width: 760px) {
        div.photo br.clear {
            display: none;
        }
    }

    body {
        background-color: #eee;
        height: 100%;
        /*overflow: hidden;*/ /*TODO: Remove this*/
    }

    .sections {
        /*display: none;*/ /*TODO: Remove this*/
    }
</style>
