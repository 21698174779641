<template>
    <div>
        <h3>LEARNING</h3>
        <div class='shell charts-animated'
         v-observe-visibility="{
  callback: visibilityChanged,
  throttle: 300, }"
          >
    
            <div v-for="tech in json.learning" class="tech_div">
    
                <h4>{{tech.title}}</h4>
                <div class='learn_image'>

                    <vue-circle 
                        ref="vuecircle"
                        :size=140 
                        :progress="determineProgress(tech.entries)" 
                        :reverse=false
                        :fill="{'color' : find_color(tech.entries)}" 
                        empty-fill="rgba(0, 0, 0, .1)" 
                        :animation="animationOptions"
                        :animation-start-value=0.0 
                        :start-angle="3*Math.PI/2"
                        insert-mode="append" 
                        :thickness=30 
                        :show-percent=true
                        >
                    </vue-circle>
    
    
    
                </div>
                <ul>
                    <li v-for="entry in tech.entries">
                        <span class='checked' v-if="entry.completed">✓&nbsp;&nbsp;</span>
                        <span class='unchecked' v-else>+&nbsp;&nbsp;</span> {{entry.text}}
                    </li>
                </ul>
            </div>
    
    
        </div>
    </div>
</template>

<script>
import json from '../../data/stack.json'
import VueCircle from 'vue2-circle-progress'
export default {
    name: "Learning",
    data() {
        return {
          animationOptions:{
            duration: 500,
            easing: "circleProgressEasing"
          },
            json: json,
            progress: 60,
    
        }
    },
    methods: {
      visibilityChanged: function(state, entry){
          if(state ){
          this.$emit("mouse", true)
          }else{
            this.$emit("mouse", false)
          }

      },
        find_color: function(inp) {
          var val = this.determineProgress(inp)
          if (val > 75) {
            //Green
            return "#8CFF83";
          }
          if (val > 50) {
            //Blue
            return "#659EFF";
          }
          if (val > 25) {
            //Orange
            return "#FFC042";
          }
          //Grey I guess?
          return "#64646e";
        },
      determineProgress(entries){
        var total = 0
        var success = 0
        var progress = 0
        entries.forEach((entry,idx)=>{
          if(entry.completed){
            success += 1
          }
          total += 1
        })
        progress = ((success/total) * 100).toFixed(0)

        if (progress < 10){
          progress = 10
        }
        return 1 * progress
      },

    },
    mounted: function() {},
    components: {
        VueCircle
    }

}
</script>

<style scoped>
.learn_image{
  margin-top: 30px;
  margin-bottom: 30px;
}
/deep/ .percent-text{
    margin-top:-7px;
    font-size: 20px !important;
    font-weight: normal;
}
</style>
