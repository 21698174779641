<template>
  <div class='shell timeline_div' id="timeline">
  <div v-for="(year,idx) in years" :class="'inset_year slide-' + determineRightLeft(idx) + ' ' + determineScroll()" v-if="year">
  
    <div class='year' v-if="year">
      <span v-if="year[0] != '3000'">{{year[0]}}</span>
      <span v-else>ONGOING</span>
    </div>
    <div class='detail_wrapper'>
    <div class='year_detail' v-for="(detail,idx) in year" v-if="idx && detail.show != false">
      <div class='photo'>
  
        <PhotoModalHelper :src="detail.photo" :title="detail['data-title']" />
      </div>
      <div class='text'>
        <h3 class='title'>{{detail.title}}</h3><br />
        <span class='content'>{{detail.text}}</span><br />

        <span v-if="detail.start == detail.end" class='timeframe'>{{detail.start}}<br />
        </span>
        <span v-else class='timeframe'>
          {{detail.start}} - {{detail.end}}
        </span>
      </div>
      <div class='tags'>
        <div v-for="tag in detail.technologies" @click="tagToggle(tag)" :class='determineTagClass(detail.show,tag)'>
          {{tag}}
        </div>
      </div>
      <br />

    </div>
  </div>
  </div>
</div>
</template>
<script>
import json from '../../data/portfolio.json'
import LazyPhoto from '@/components/LazyPhotos'
import PhotoModalHelper from '@/components/PhotoModalHelper'
export default {
    components: {
      LazyPhoto,
      PhotoModalHelper 
    },
    data() {
        return {
            json: json,
            years: [],
            tagged: false,
            latched: false
        }
    },
    methods:{
      determineTagClass: function(show,tag){
        if(this.tagged){
          if(show == tag){
            return "tagged"
          }else{
            return "grey_tag"
          }
        }
      },
      determineScroll(){
        if(!this.tagged && !this.latched){
          return "animated-scroll"
        }
        this.latched = true
        return ""
      },
      determineRightLeft: function(idx){
        if(idx % 2 == 0){
          return "left";
        }
        return "right";
      },
      showAll: function(){
        this.years.forEach(year=>year.forEach((item,idx)=>{
            if(idx){
              item.show = true
            }
          }))
          this.$forceUpdate()

      },
      tagToggle: function(it){
        if(this.tagged && this.tagged == it){
          this.showAll()
          this.tagged = false
        }else{
          this.years.forEach(year=>{
            year.forEach((item, idx)=>{
              if(idx){
                //Year is the first element of the array, so skip that
                var found = item.technologies.reduce((sum, cur) => sum + (cur == it),0)
                if(found > 0){
                  item.show = it
                }else{
                  item.show = false
                }

              }
            })
          })
          this.tagged = it
        }
      
        this.$scrollTo("div.projects")
        this.$forceUpdate()
        this.$emit("scroll")
      }
           
      },
    mounted: function(){
        var projects = json.projects

        projects.forEach(j => {
            if(j.end == "ongoing"){
                j.end = "3000"
              }

            if (this.years[j.end] == undefined) {
                this.years[j.end] = [j.end]
            }
                        this.years[j.end].push(j)
            j.show = true
        })
        this.years.reverse()
        //this.years.unshift(years['ongoing'])
      
    
      }
}
</script>
<style lang="scss" scoped>
</style>

