<template>
  <div id="app">
    <div id="nav">
      <!--<router-link to="/">Home</router-link>-->
    </div>
    <div v-if="$root.loading" class='mt-5'>
      <img height="100px" src="/loading.gif" alt="Loading..." />
    </div>
    <router-view v-else />
  </div>
</template>
<script>  
export default{
  props: ["loading"]
}
</script>

<style lang="scss">
$lightblue: #1F1BB5;
$blue: rgb(62, 72, 89);

.btn-primary{
  background-color: $blue !important; 
  border-color: $blue !important;
}
div.contact_left a{
  color: $lightblue !important;
}
div.contact_left a:hover{
  color: $lightblue !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 ;
  padding: 0;
}


</style>
